import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../scss/site.scss"
import "../scss/kontakt.scss"

const KontaktPage = () => {
  return (
    <Layout>
      <SEO
        title="Kontakt | freie ingenieure bau"
        description="Kontakt"
        lang="de"
      />
      <div className="fib-sp80"></div>
      <div className="fib-sp40 uk-visible@m"></div>
      <div className="fib-container-text">
        <div className="uk-width-8-12@m">
          <h2 className="fib-ft54 fib-ft-bold">
            Sie ha&shy;ben Fra&shy;gen oder brau&shy;chen ei&shy;nen
            zu&shy;ver&shy;läs&shy;sigen Part&shy;ner für Ihr Bauvorhaben?
          </h2>
          <h1 className="fib-ft54 fib-ft-bold fib-ftgrey-light">
            Nehmen Sie Kontakt&nbsp;auf.
          </h1>
        </div>
        <div className="fib-sp80"></div>
        <p className="fib-ft28 fib-ft-medium">
          Elvirasteig 11
          <br />
          14163 Berlin – Wilmersdorf
        </p>
        <div className="fib-sp20"></div>
        <p className="fib-ft28 fib-ft-medium fib-ft-blue">
          <Obfuscate
            className="fib-text-link"
            email="info@freie-ingenieure-bau.de"
          >
            info@freie-ingenieure-bau.de
          </Obfuscate>
          <br />
          <span itemProp="telephone">
            <a className="fib-text-link" href="tel:+493098422780">
              T +49 30 98422780
            </a>
          </span>
          <br />
          <span itemProp="telephone">
            <a className="fib-text-link" href="tel:+493022185925">
              F +49 30 22185925
            </a>
          </span>
        </p>
      </div>
    </Layout>
  )
}

export default KontaktPage
